import * as React from "react";
import {
  List,
  TextField,
  DateField,
  FunctionField,
  Filter,
  TextInput,
} from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import EmptyList from "../EmptyList";
import { GetPermissionNamesFromArray } from "../../config/PermissionHandler";

export default (props) => {
  return (
    <List
      {...props}
      title={`Użytkownicy`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="użytkowników" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="E-mail"
            source="email"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="username" label="Nazwa użytkownika" />
        <FunctionField
          render={(record) => GetPermissionNamesFromArray(record.roles)}
          label="Uprawienia"
        />
        <FunctionField
          render={(record) => {
            return new Date(record?.createdAt) != "Invalid Date" ? (
              <DateField record={record} source="createdAt" />
            ) : (
              "Nieprawidłowa data"
            );
          }}
          label="Utworzono"
        />
        <FunctionField
          render={(record) => {
            return new Date(record?.createdAt) != "Invalid Date" ? (
              <DateField record={record} source="updatedAt" />
            ) : (
              "Nieprawidłowa data"
            );
          }}
          label="Modyfikowano"
        />
        {/* <DateField source="createdAt" label="Utworzono" />
        <DateField source="updatedAt" label="Modyfikowano" /> */}
        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
