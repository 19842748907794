import * as React from "react";
import {
  List,
  TextField,
  BooleanField,
  NumberField,
  FunctionField,
} from "react-admin";
import { createStyles, makeStyles } from "@material-ui/core";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import EmptyList from "../EmptyList";

const GetTypeName = (position) => {
  switch (position) {
    // case 0:
    //   return "Baner";
    case 1:
      return "Boks";
    case 2:
      return "Boks AR";
    default:
      return "Błąd typu";
  }
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();

  let customProps = {
    basePath: "/banners",
    hasCreate: true,
    hasEdit: true,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: "/boxes", search: "", hash: "", state: undefined },
    match: { path: "/boxes", url: "/boxes", isExact: true, params: {} },
    options: {},
    permissions: "ROLE_SUPER_ADMIN,ROLE_USER",
    resource: "banners",
    syncWithLocation: true,
  };

  return (
    <List
      {...customProps}
      title={`Boksy`}
      bulkActionButtons={false}
      actions={<CustomActionBar customBasePath={"/boxes"} />}
      empty={<EmptyList title="boksów" add={true} />}
      filter={{ type: [1, 2] }}
    >
      <CustomDataGrid>
        <FunctionField
          source="id"
          label="Id"
          render={(record) => record.id.split("/")[2]}
        />
        <TextField source="name" label="Nazwa" />
        <FunctionField
          source="type"
          label="Typ"
          render={(record) => GetTypeName(record.type)}
        />
        <NumberField source="position" label="Pozycja" />
        <BooleanField source="public" label="Publiczne" className={classes.root}/>
        <CustomActionsButtons label="Akcje" customBasePath={"/boxes"} notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
