import React, { useState } from "react";
import {
  TabbedForm,
  FormTab,
  Create,
  BooleanInput,
  TextInput,
} from "react-admin";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

export default (props) => {
  const [type, setType] = useState();

  return (
    <Create {...props} title={`Dodaj rodzaj produktu`}>
      <TabbedForm toolbar={<PostBottomToolbarAdd />}>
        <FormTab label="Zawartość">
          <div className="d-flex w-100 justify-content-around">
            <div class="col-md-4">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Nazwa"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="status"
                        label="Status"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
