import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery, Divider } from "@material-ui/core";
import { MenuItemLink, getResources, useDataProvider } from "react-admin";
import { withRouter } from "react-router-dom";
import { usePermissions } from "react-admin";
import authProvider from "../../config/authProvider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faListAlt,
  faAd,
  faAddressBook,
  faAlignCenter,
  faMapMarkerAlt,
  faImages,
  faBoxes,
  faBoxOpen,
  faUsers,
  faMapMarkedAlt,
  faPollH,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";

const Menu = ({ onMenuClick, logout }) => {
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const { loaded, permissions } = usePermissions();

  return loaded ? (
    <div className="left-menu">
      {/* <MenuItemLink
        to="/#"
        primaryText="Strona główna"
        leftIcon={<FontAwesomeIcon icon={faHome} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      /> */}
      <MenuItemLink
        to="/categories"
        primaryText="Kategorie"
        leftIcon={<FontAwesomeIcon icon={faListAlt} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/banners"
        primaryText="Banery"
        leftIcon={<FontAwesomeIcon icon={faAd} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/boxes"
        primaryText="Boksy"
        leftIcon={<FontAwesomeIcon icon={faPollH} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {/* <MenuItemLink
        to="/contact_forms"
        primaryText="Formy kontaktowe"
        leftIcon={<FontAwesomeIcon icon={faAddressBook} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      /> */}
      {/* <MenuItemLink
        to="/contents"
        primaryText="Lista dokumentów"
        leftIcon={<FontAwesomeIcon icon={faAlignCenter} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      /> */}
      <MenuItemLink
        to="/map_points"
        primaryText="Punkty na mapie"
        leftIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/media_objects"
        primaryText="Repozytorium zdjęć"
        leftIcon={<FontAwesomeIcon icon={faImages} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/files"
        primaryText="Repozytorium plików"
        leftIcon={<FontAwesomeIcon icon={faFileUpload} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/product_types"
        primaryText="Rodzaje produktów"
        leftIcon={<FontAwesomeIcon icon={faBoxes} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/products"
        primaryText="Produkty"
        leftIcon={<FontAwesomeIcon icon={faBoxOpen} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/users"
        primaryText="Użytkownicy"
        leftIcon={<FontAwesomeIcon icon={faUsers} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {/* <MenuItemLink
        to="/voivodeships"
        primaryText="Wojewodztwa"
        leftIcon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      /> */}
      {isXSmall && logout}
    </div>
  ) : null;
};

export default withRouter(Menu);
