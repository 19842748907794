import * as React from "react";
import {
  List,
  TextField,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ImageField,
  FunctionField,
  Filter,
  TextInput,
  useDataProvider,
  useGetList,
  ArrayField,
  useQuery
} from "react-admin";
import { useEffect, useState } from "react";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { createStyles, makeStyles } from "@material-ui/core";

import EmptyList from "../EmptyList";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [count, setCount] = useState();
  const [isFetched, setIsFetched] = useState(false);


  const CategoriesNames = {
    '/categories/herbicydy': "Herbicydy",
    ROLE_ADMIN: "ra.module.users.admin",
    ROLE_USER: "ra.module.users.user",
  };

  const GetCategoriesNamesFromArray = (names) => {
    return Array.isArray(names)
      ? names?.map((x) => CategoriesNames[x])?.join(" ")
      : names;
  };



  return (
    <List
      {...props}
      title={`Produkty`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="produktów" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <ImageField source="packshotUrl" label="Opakowanie" sortable={false} />
        {/* <FunctionField
          label="test"
          render={(record) => {
            return `${record.packshotUrl}`;
          }}
        /> */}

        <TextField source="name" label="Nazwa" />

        {/*
        <ReferenceArrayField
          source="category"
          reference="categories"
          label="Kategoria"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      */}

        <FunctionField
          label="Kategoria"
          render={(record) => {
            const name = (record.category.toString().replace(/([^a-z0-9-])/gi, '')).replace(/categories/g, ' ');
            const nameArray = name.split(" ");
            const rows = [];
            for (let i = 0; i < nameArray.length; i++) {
              rows.push(<a href={`#/categories/%2Fcategories%2F${(nameArray[i])}`}>
                {nameArray[i] + " "}
              </a>);
            }
            return (rows)
          }}
        />

        {/* <ReferenceManyField
          source="type"
          reference="product_types"
          target="SlugId"
          label="Typ"
        >
          <TextField source="name" />
        </ReferenceManyField> */}

        <FunctionField
          label="Typ"
          render={(record) => (
            <a href={`#/product_types/${encodeURIComponent(record.type)}`}>
              {record.typeName}
            </a>
          )}
        />

        <BooleanField
          source="showDescription"
          label="Pokazywać opis"
          className={classes.root}
        />

        <BooleanField
          source="public"
          label="Publiczne"
          className={classes.root}
        />


        {/* <FunctionField
          label="Typ"
          render={(record) => {
            //console.log(record);
            dataProvider
              .getOne("product_types", { id: record.type })
              .then(({ data }) => {
                console.log(data);
              })
              .catch((error) => {
              });
            return `${record.type}`;
          }}
        /> */}

        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
