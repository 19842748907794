import * as React from "react";
import {
  List,
  TextField,
  BooleanField,
  Datagrid,
  ReferenceField,
  ReferenceArrayField,
  ReferenceManyField,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  DateField,
  FunctionField,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import EmptyList from "../EmptyList";

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();

  const documentType = (type) => {
    switch (type) {
      case 0:
        return "Descriptive subpage";
      case 2:
        return "Agroadvisory";
      case 3:
        return "Blog";
      case 4:
        return "Kontakt - oddziały";
      case 5:
        return "Kontakt - siedziba";
      case 6:
        return "Kontakt - sklepy";
      case 7:
        return "Kontakt - elewatory";
      default:
        return "-";
    }
  };

  return (
    <List
      {...props}
      title={`Lista dokumentów`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="dokumentów" add={true} />}
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <ReferenceArrayField
          source="category"
          reference="categories"
          label="Kategoria"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          source="type"
          render={(record) => documentType(record.type)}
          label="Typ dokumentu"
        />
        <BooleanField source="public" label="Status" className={classes.root} />
        <DateField source="createdAt" label="Utworzono" />
        <DateField source="updatedAt" label="Modyfikowano" />
        <ReferenceField source="author" reference="users" label="Autor">
          <TextField source="email" />
        </ReferenceField>
        <CustomActionsButtons label="Akcje" notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
