import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  TextInput,
} from "react-admin";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const CustomTitle = (props) => `Punkt na mapie ${props.record.name}`;

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarEdit {...props} />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="street"
                      label="Ulica"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="city"
                      label="Miasto"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="borough"
                      label="Gmina"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceInput
                      reference="voivodeships"
                      source="voivodeship"
                      label="Zdjęcie"
                    >
                      <SelectInput
                        optionText="name"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </ReferenceInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="postCode"
                      label="Kod pocztowy"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="phone"
                      label="Numer telefonu"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="lat"
                      label="Lat"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="lng"
                      label="Lng"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="godzinypnpt"
                      label="Godziny pn-pt"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="godzinysb"
                      label="Godziny sb"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                      source="public"
                      label="Publiczne"
                      variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
