import * as React from "react";
import { Route } from 'react-router-dom';
import BoxesCreate from "../layout/Boxes/BoxesCreate";
import BoxesList from "../layout/Boxes/BoxesList";
import ResetPassword from '../layout/Login/ResetPassword';
import FilePluginGallery from "../layout/TinyMCE-PluginsViews/FilePluginGallery";
import ImagePluginGallery from "../layout/TinyMCE-PluginsViews/ImagePluginGallery";


export default [
  <Route exact path="/reset_password" component={ResetPassword} noLayout />,
  <Route exact path="/boxes" component={BoxesList} />,
  <Route exact path="/boxes/create" component={BoxesCreate} />,
  <Route exact path="/tinymce_image_gallery" component={ImagePluginGallery} noLayout />,
  <Route exact path="/tinymce_file_gallery" component={FilePluginGallery} noLayout />,
];
