import React, { ReactDOM } from "react";
import { Layout, AppBar, ImageField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { useNotify } from "react-admin";
import "../App.css"; //plik css
import logo from "../logo.svg";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: -10,
  },

  logo: {
    maxWidth: "35px",
    marginLeft: "10px",
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  const notify = useNotify();

  const restart = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/restart-front`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((data) => {
      data
        .json()
        .then((json) =>
          notify(
            json.success
              ? "Cache został wyczyszczony"
              : "Wystąpił błąd przy czyszczeniu cache"
          )
        );
    });
  };

  return (
    <AppBar
      {...props}
      className={"top-admin-bar"}
      style={{ backgroundColor: "#fff" }}
    >
      <Typography variant="h6" style={{ color: "#e30815" }}>
        Witaj w panelu admina
      </Typography>

      <img src={logo} alt="logo" className={classes.logo} />
      <div className={"spacer"}></div>
      <button class="btn btn-outline-warning btn-lg mr-4" onClick={restart}>
        Wyczyszcz cache
      </button>
      <Typography
        variant="h6"
        style={{ color: "#e30815" }}
        id="react-admin-title"
      />
    </AppBar>
  );
};

const MainLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

export default MainLayout;
