import * as React from "react";
import {
  EditButton,
  DeleteButton,
  ShowButton,
  Button,
  Link,
  useRefresh,
} from "react-admin";
import EditIcon from "@material-ui/icons/Edit";

const CustomEditButton = (props) => {
  return (
    <EditButton
      class={"btn btn-success"}
      style={{ display: "flex", flexDirection: "row", margin: "0 1% 0 0" }}
      {...props}
      label={props.editLabel ? props.editLabel : "Edytuj"}
    />
  );
};

export const CustomActionButtonEdit = (props) => {
  return <CustomEditButton {...props} />;
};

const CustomDeleteButton = (props) => {
  const refresher = useRefresh();
  return (
    <DeleteButton
      class={"btn btn-danger"}
      style={{ display: "flex", flexDirection: "row", margin: "0 0 0 1%" }}
      {...props}
      label={props.deleteLabel ? props.deleteLabel : "Usuń"}
      basePath={props.customBasePath ? props.customBasePath : props.basePath}
      onSuccess={props.onSuccess && props.onSuccess}
      mutationMode={props.notUndoable ? "pessimistic" : "undoable"}
    />
  );
};

export const CustomActionButtonDelete = (props) => {
  return <CustomDeleteButton {...props} />;
};

const EditButtonCustomId = (props) => {
  return (
    <Button
      label="Edytuj"
      class="btn btn-success"
      style={{ display: "flex", flexDirection: "row", margin: "0 1% 0 0" }}
      size="large"
      href={
        props.customId
          ? `#/${props.resource}/${encodeURIComponent(
              props.record[props.customId]
            )}`
          : props.record.id
      }
      startIcon={<EditIcon />}
    />
  );
};

export const CustomActionsButtons = (props) => {
  const {
    isEditAvailable = true,
    isDeleteAvailable = true,
    customId = null,
    undoable,
  } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {customId && <EditButtonCustomId {...props} />}
      {isEditAvailable && <CustomEditButton {...props} />}
      {isDeleteAvailable && <CustomDeleteButton {...props} />}
    </div>
  );
};
