import * as React from "react";
import {
  List,
  TextField,
  BooleanField,
  ReferenceField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import EmptyList from "../EmptyList";

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title={`Punkty na mapie`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="punktów na mapie" add={true} />}
      filters={
        <Filter>
          <ReferenceInput
            label="Województwo"
            source="voivodeship"
            reference="voivodeships"
            variant="outlined"
            alwaysOn
            resettable
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="city" label="Miasto" />
        <TextField source="street" label="Ulica" />
        <ReferenceField
          source="voivodeship"
          reference="voivodeships"
          label="Województwo"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="phone" label="Numer telefonu" />
        <BooleanField
          source="public"
          label="Publiczne"
          className={classes.root}
        />
        <CustomActionsButtons label="Akcje" notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
