import React, { useState } from "react";
import {
  TabbedForm,
  FormTab,
  Edit,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  NumberInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  useGetOne,
} from "react-admin";
import { Field, useField } from "react-final-form";

import MSTypeBody from "./MSTypeBody";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const CustomTitle = (props) => `Produkt ${props.record.name}`;

export default (props) => {
  const [type, setType] = useState();

  return (
    <Edit {...props} title={<CustomTitle />}>
      <TabbedForm toolbar={<PostBottomToolbarEdit {...props} />}>
        <FormTab label="Zawartość">
          <FormDataConsumer>
            {({ formData, record, ...rest }) => {
              const { data, loading, error } = useGetOne(
                "product_types",
                formData.type
              );
              data && setType(data);
            }}
          </FormDataConsumer>
          <div className="d-flex w-100 justify-content-around">
            <div class="col-xs-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Nazwa"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceArrayInput
                        reference="categories"
                        source="category"
                        label="Kategoria"
                        perPage={1000}
                      >
                        <AutocompleteArrayInput
                          optionText={(record) =>
                            record &&
                            (record.parentName
                              ? `${record.parentName} - ${record.name}`
                              : record.name)
                          }
                          variant="outlined"
                          fullWidth={true}
                        />
                      </ReferenceArrayInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceInput
                        reference="product_types"
                        source="type"
                        label="Typ"
                        perPage={1000}
                        className="HideErrors"
                        //format={(v) => v && v.split("%2F")[2]}
                      >
                        <SelectInput
                          variant="outlined"
                          fullWidth={true}
                          error={false}
                          helperText={false}
                        />
                      </ReferenceInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AddImagePopup
                        source="packshot"
                        reference="media_objects"
                        label="Opakowanie"
                        validateImgHeight={1000}
                        validateImgWidth={1000}
                      />
                    </td>
                  </tr>
                  {/* <tr> //TODO which field in base?
                  <td>
                    <ImageInput source="image" label="Zdjęcie" variant="outlined" />
                  </td>
                </tr> */}
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Opis krótki
                      </label>
                      <Field name="short">
                        {(props) => (
                          <TinyMCEInput
                            source="short"
                            height={200}
                            menubar={false}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Opis długi
                      </label>
                      <Field name="descr">
                        {(props) => <TinyMCEInput source="descr" />}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        name="showDescription"
                        label="Pokazywać pole opis na stronie produktu"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Wyniki badań wg COBORu
                      </label>
                      <Field name="t2Cobor">
                        {(props) => <TinyMCEInput source="t2Cobor" />}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        source="position"
                        label="Pozycja"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Słowa kluczowe dla wyszukiwarki
                      </label>
                      <TextInput
                        source="searchKeywords"
                        label=""
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="public"
                        label="Czy wyświetlać produkt?"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        source="producerName"
                        label="Nazwa producenta/hodowcy"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AddImagePopup
                        source="producerImage"
                        reference="media_objects"
                        label="Logo producenta/hodowcy"
                        validateImgHeight={45}
                        validateImgWidth={125}
                      />
                    </td>
                  </tr>
                  {/* //TODO problems list??? */}
                  <tr>
                    <td>
                      <BooleanInput
                        source="promoted"
                        label="Oznacz czy produkt ma być wyświetlany na stronie głównej"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Film reklamowy - link do Youtube w postaci:
                        https://www.youtube.com/embed/xxxxxxxxx
                        <br />
                        - gdzie xxxxxxxxx zastępujemy kodem filmu stanowiącym
                        fragment adresu filmu na Youtube,
                        <br />
                        np. dla https://www.youtube.com/watch?v=nBUsUyDF0J4
                        <br />
                        prawidłowym linkiem jest
                        https://www.youtube.com/embed/nBUsUyDF0J4
                        <br />
                      </label>
                      <TextInput
                        source="youtube"
                        label=""
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Pliki do pobrania (lista widoczna w nagłówku strony
                        produktowej) Skorzystaj z formatu listy oznaczonego jako
                        'ul.chem-style2'
                      </label>
                      <Field name="download">
                        {(props) => <TinyMCEInput source="download" />}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="kurkaAvailable"
                        label="Zaznacz czy produkt jest dostępny w sieci Kurka"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="new"
                        label="Oznacz jako nowość"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        source="link"
                        label="Link - 'Czytaj więcej'"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>

        {type && type.slug != "inny" && type.slug != "nawozy-azoty" && (
          <FormTab label={`MS - ${type.name}`}>
            <MSTypeBody type={type} />
          </FormTab>
        )}

        <FormTab label="Filtry">
          <div className="d-flex w-100 justify-content-around">
            <div class="col-md-4">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <SelectInput
                        source="filterQualityClass"
                        variant="outlined"
                        label="Filtr quality class"
                        choices={[
                          { id: 0, name: "wybierz klasę jakościową" },
                          { id: 1, name: "E - elitarna" },
                          { id: 2, name: "A - jakościowa" },
                          { id: 3, name: "B - chlebowa" },
                          { id: 4, name: "K - na ciastka" },
                          {
                            id: 5,
                            name: "C - odmiany pozostałe w tym paszowe",
                          },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterSoilRequirements"
                        variant="outlined"
                        label="Filtr soil requirements"
                        choices={[
                          { id: 0, name: "wybierz wymagania glebowe" },
                          { id: 1, name: "Niskie" },
                          { id: 2, name: "Średnie" },
                          { id: 3, name: "Średnie do wysokich" },
                          { id: 4, name: "Wysokie" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterFao"
                        variant="outlined"
                        label="Filtr FAO"
                        choices={[
                          { id: 0, name: "wybierz FAO" },
                          {
                            id: 1,
                            name: "do FAO 190 (odmiany bardzo wczesne)",
                          },
                          { id: 2, name: "FAO 200-220 (odmiany wczesne)" },
                          {
                            id: 3,
                            name: "FAO 230-240 (odmiany średnio wczesne)",
                          },
                          {
                            id: 4,
                            name: "FAO 250-290 (odmiany średnio późne)",
                          },
                          { id: 5, name: "FAO 300 i powyżej (odmiany późne)" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterSuggestedUse"
                        variant="outlined"
                        label="Filtr suggested use"
                        choices={[
                          {
                            id: 0,
                            name: "wybierz rekomendowane przeznaczenie",
                          },
                          { id: 1, name: "Ziarno" },
                          { id: 2, name: "Bioetanol" },
                          { id: 3, name: "Biogaz" },
                          { id: 4, name: "Przemysł gorzelniczy" },
                          { id: 5, name: "Przemysł młynarski" },
                          { id: 6, name: "Kiszonka" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterBrand"
                        variant="outlined"
                        label="Filtr brand"
                        choices={[
                          { id: 0, name: "wybierz odmianę" },
                          { id: 1, name: "Giant" },
                          { id: 2, name: "Caterpillar" },
                          { id: 3, name: "Kverneland" },
                          { id: 4, name: "Wielton" },
                          { id: 5, name: "Joskin" },
                          { id: 6, name: "Krukowiak" },
                          { id: 7, name: "Kongskilde" },
                          { id: 8, name: "Expom Krośniewice" },
                          { id: 9, name: "Akpil" },
                          { id: 10, name: "Agro-Masz" },
                          { id: 11, name: "Kubota" },
                          { id: 12, name: "John Deere" },
                          { id: 13, name: "inna" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterType"
                        variant="outlined"
                        label="Filtr type"
                        choices={[
                          { id: 0, name: "wybierz odmianę" },
                          { id: 1, name: "Ziarnowa" },
                          { id: 2, name: "Uniwersalna" },
                          { id: 3, name: "Kiszonkowa" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterType2"
                        variant="outlined"
                        label="Filtr type2"
                        choices={[
                          { id: 0, name: "wybierz odmianę" },
                          { id: 1, name: "Mieszńcowa" },
                          { id: 2, name: "Populacyjna" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterType3"
                        variant="outlined"
                        label="Filtr type3"
                        choices={[
                          { id: 0, name: "wybierz odmianę" },
                          { id: 1, name: "Ozimy" },
                          { id: 2, name: "Jary" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="filterFertilizerType"
                        variant="outlined"
                        label="Filtr fertilizer type"
                        choices={[
                          { id: 0, name: "wybierz typ nawozu" },
                          { id: 1, name: "Wapniowe" },
                          { id: 2, name: "Mikronawozy,startowe" },
                          { id: 3, name: "Magnezowo-siarkowe" },
                          { id: 4, name: "Magnezowo-siarkowe dolistne" },
                          { id: 5, name: "Potasowe" },
                          { id: 6, name: "Azotowe" },
                          { id: 7, name: "Azotowe z siarką" },
                          { id: 8, name: "Wieloskładnikowe" },
                          { id: 9, name: "Fosforowe" },
                          { id: 10, name: "Tlenkowe" },
                          { id: 11, name: "Węglanowe" },
                          { id: 12, name: "Dolomitowe" },
                        ]}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
        <FormTab label="SEO">
          <div className="d-flex w-100 justify-content-around">
            <div class="col-md-4">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="title"
                        label="Tytuł"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        source="slug"
                        label="Slug – przyjazny adres"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
        <FormTab label="Powiązane produkty">
          <div className="d-flex w-100 justify-content-around">
            <div class="col-md-4">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <ReferenceArrayInput
                        reference="products"
                        source="related"
                        label="Lista produktów"
                        perPage={1000}
                      >
                        <AutocompleteArrayInput
                          optionText="name"
                          variant="outlined"
                          fullWidth={true}
                        />
                      </ReferenceArrayInput>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
