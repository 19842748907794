import * as React from "react";
import {
  ListButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  useRedirect,
} from "react-admin";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

export const PostBottomToolbarEdit = (props) => {
  //console.log(props);
  const redirect = useRedirect();
  return (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton
        //class={"btn btn-success btn-lg"}
        className="btn-ok"
        redirect={
          props.saveRedirectPath ? props.saveRedirectPath : props.basePath
        }
        submitOnEnter={true}
        size="large"
      />
      {/* disabled={props.invalid} pozwala na wyłączenia przycisku w razie błędu walidacji, ale
        przy pojawieniu błędu walidacji nadpisuje klasę bootstrapową oraz wszytskie klasy (bug react admina?),
        ale inline styles działają */}
      <Button
        label="Anuluj"
        //class={"btn btn-secondary btn-lg"}
        className="btn-cancel"
        // basePath={
        //   props.saveRedirectPath ? props.saveRedirectPath : props.basePath
        // }
        size="large"
        onClick={() =>
          redirect(
            props.saveRedirectPath ? props.saveRedirectPath : props.basePath
          )
        }
        startIcon={<FontAwesomeIcon icon={faUndo} />}
      />
      {(props.isDeleteAvailable == undefined ||
        props.isDeleteAvailable == false) && (
        <DeleteButton
          //class={"btn btn-danger btn-lg"}
          className="btn-delete"
          size="large"
          redirect={
            props.deleteRedirectPath ? props.deleteRedirectPath : props.basePath
          }
        />
      )}
    </Toolbar>
  );
};

export const PostBottomToolbarAdd = (props) => {
  const redirect = useRedirect();
  return (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton
        //class={"btn btn-success btn-lg"}
        className="btn-ok"
        redirect={
          props.saveRedirectPath ? props.saveRedirectPath : props.basePath
        }
        submitOnEnter={true}
        size={"large"}
      />
      {/* disabled={props.invalid} pozwala na wyłączenia przycisku w razie błędu walidacji, ale
        przy pojawieniu błędu walidacji nadpisuje klasę bootstrapową oraz wszytskie klasy (bug react admina?),
        ale inline styles działają */}
      <Button
        label="Anuluj"
        //class={"btn btn-secondary btn-lg"}
        className="btn-cancel"
        //basePath={props.basePath}
        onClick={() =>
          redirect(
            props.saveRedirectPath ? props.saveRedirectPath : props.basePath
          )
        }
        size={"large"}
        startIcon={<FontAwesomeIcon icon={faUndo} />}
      />
    </Toolbar>
  );
};
