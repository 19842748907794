import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  required,
} from "react-admin";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={`Dodaj użytkownika`} >
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="email"
                      label="Adres e-mail"
                      variant="outlined"
                      fullWidth={true}
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <SelectInput
                      source="roles"
                      label="Uprawienia"
                      validate={requiredField}
                      variant="outlined"
                      fullWidth={true}
                      choices={
                        authProvider.hasRole(["ROLE_SUPER_ADMIN"])
                          ? [
                              {
                                id: "ROLE_SUPER_ADMIN,ROLE_USER",
                                name: "Admin wszystkiego",
                              },
                              {
                                id: "ROLE_ADMIN,ROLE_USER",
                                name: "Admin",
                              },
                              { id: "ROLE_USER", name: "Użytkownik" },
                            ]
                          : authProvider.hasRole(["ROLE_ADMIN"])
                          ? [
                              {
                                id: "ROLE_ADMIN,ROLE_USER",
                                name: "Admin",
                              },
                              { id: "ROLE_USER", name: "Użytkownik" },
                            ]
                          : []
                      }
                    />
                  </th>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="password"
                      label="Hasło"
                      variant="outlined"
                      type="password"
                      fullWidth={true}
                      validate={requiredField}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
