import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";

import { Resource } from "react-admin";

// config
//const { permissions } = usePermissions();
import authProvider from "./config/authProvider";
import customRoutes from "./config/customRoutes";

// pages
//import Login from "./layout/Login";
import Login from "./layout/Login/Login";
import Menu from "./layout/Menu/Menu";
//import Dashboard from "./layout/Dashboard/Dashboard";
//import NotFound from "./layout/NotFound";
import MainLayout from "./layout/MainLayout";
//import Dashboard from "./layout/Dashboard";
// pages - end

// custom views
import CategoriesList from "./layout/Categories/CategoriesList";
import MapPointsList from "./layout/MapPoints/MapPointsList";
import ProductsList from "./layout/Products/ProductsList";
import UsersList from "./layout/Users/UsersList";
import ProductsTypesList from "./layout/ProductTypes/ProductsTypesList";
import BannersList from "./layout/Banners/BannersList";
import ContentsList from "./layout/Contents/ContentsList";
import ContactFormsList from "./layout/ContactForms/ContactFormsList";
import MediaObjectsList from "./layout/MediaObjects/MediaObjectsList";
import MediaObjectsCreate from "./layout/MediaObjects/MediaObjectsCreate";
import MediaObjectsEdit from "./layout/MediaObjects/MediaObjectsEdit";
import ContentsCreate from "./layout/Contents/ContentsCreate";
import ContentsEdit from "./layout/Contents/ContentsEdit";
import ProductsCreate from "./layout/Products/ProductsCreate";
import ProductsEdit from "./layout/Products/ProductsEdit";
import UsersCreate from "./layout/Users/UsersCreate";
import UsersEdit from "./layout/Users/UsersEdit";
import MapPointsCreate from "./layout/MapPoints/MapPointsCreate";
import MapPointsEdit from "./layout/MapPoints/MapPointsEdit";
import CategoriesEdit from "./layout/Categories/CategoriesEdit";
import CategoriesCreate from "./layout/Categories/CategoriesCreate";
import ProductsTypesCreate from "./layout/ProductTypes/ProductsTypesCreate";
import ProductsTypesEdit from "./layout/ProductTypes/ProductsTypesEdit";
import BannersCreate from "./layout/Banners/BannersCreate";
import BannersEdit from "./layout/Banners/BannersEdit";
import FilesCreate from "./layout/Files/FilesCreate";
import FilesEdit from "./layout/Files/FilesEdit";
import FilesList from "./layout/Files/FilesList";
// custom views - end

//stylesheet
import "./css/style.css";
//

// i18n
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import polishMessages from "ra-language-polish";
import { PermissionHandler } from "./config/PermissionHandler";

const messages = {
  pl: polishMessages,
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pl");
// i18n - end

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});
const fetchHydra = (url, options = {}) =>
  localStorage.getItem("token")
    ? baseFetchHydra(url, {
        ...options,
        headers: new Headers(fetchHeaders()),
      })
    : baseFetchHydra(url, options);
const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(
    entrypoint,
    localStorage.getItem("token")
      ? {
          headers: new Headers(fetchHeaders()),
        }
      : {}
  ).then(
    ({ api }) => ({ api }),
    (result) => {
      // Only useful when the API endpoint is secured
      if (result.status === 401) {
        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");
        return Promise.resolve({
          api: result.api,
          customRoutes: [
            <Route
              path="/"
              render={() =>
                localStorage.getItem("token") ? (
                  window.location.reload()
                ) : (
                  <Redirect to="/login" />
                )
              }
            />,
          ],
        });
      }
      return Promise.reject(result);
    }
  );
const dataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser
);

export default () => (
  <>
    <HydraAdmin
      title="Panel CMS A100"
      locale="pl"
      entrypoint={entrypoint}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      // pages
      menu={Menu}
      loginPage={Login}
      //dashboard={Dashboard}
      layout={MainLayout}
      //catchAll={NotFound}
    >
      {(permissions) => [
        PermissionHandler(permissions, "Categories", "Read") && (
          <Resource
            name="categories"
            list={CategoriesList}
            edit={
              PermissionHandler(permissions, "Categories", "Update") &&
              CategoriesEdit
            }
            create={
              PermissionHandler(permissions, "Categories", "Create") &&
              CategoriesCreate
            }
          />
        ),
        PermissionHandler(permissions, "Banners", "Read") && (
          <Resource
            name="banners"
            list={BannersList}
            create={
              PermissionHandler(permissions, "Banners", "Update") &&
              BannersCreate
            }
            edit={
              PermissionHandler(permissions, "Banners", "Create") && BannersEdit
            }
          />
        ),
        //<Resource name="contact_forms" list={ContactFormsList} />,
        // <Resource
        //   name="contents"
        //   list={ContentsList}
        //   create={ContentsCreate}
        //   edit={ContentsEdit}
        // />,
        PermissionHandler(permissions, "MapPoints", "Read") && (
          <Resource
            name="map_points"
            list={MapPointsList}
            create={
              PermissionHandler(permissions, "MapPoints", "Create") &&
              MapPointsCreate
            }
            edit={
              PermissionHandler(permissions, "MapPoints", "Update") &&
              MapPointsEdit
            }
          />
        ),
        PermissionHandler(permissions, "MediaObjects", "Read") && (
          <Resource
            name="media_objects"
            list={MediaObjectsList}
            create={
              PermissionHandler(permissions, "MediaObjects", "Create") &&
              MediaObjectsCreate
            }
            edit={
              PermissionHandler(permissions, "MediaObjects", "Update") &&
              MediaObjectsEdit
            }
          />
        ),
        PermissionHandler(permissions, "ProductTypes", "Read") && (
          <Resource
            name="product_types"
            list={ProductsTypesList}
            create={
              PermissionHandler(permissions, "ProductTypes", "Create") &&
              ProductsTypesCreate
            }
            edit={
              PermissionHandler(permissions, "ProductTypes", "Update") &&
              ProductsTypesEdit
            }
          />
        ),
        PermissionHandler(permissions, "Products", "Read") && (
          <Resource
            name="products"
            list={ProductsList}
            create={
              PermissionHandler(permissions, "Products", "Create") &&
              ProductsCreate
            }
            edit={
              PermissionHandler(permissions, "Products", "Update") &&
              ProductsEdit
            }
          />
        ),
        PermissionHandler(permissions, "Users", "Read") && (
          <Resource
            name="users"
            list={UsersList}
            create={
              PermissionHandler(permissions, "Users", "Create") && UsersCreate
            }
            edit={
              PermissionHandler(permissions, "Users", "Update") && UsersEdit
            }
          />
        ),
        PermissionHandler(permissions, "Files", "Read") && (
          <Resource
            name="files"
            list={FilesList}
            create={
              PermissionHandler(permissions, "Files", "Create") && FilesCreate
            }
            edit={
              PermissionHandler(permissions, "Files", "Update") && FilesEdit
            }
          />
        ),
        <Resource name="voivodeships" />,
      ]}
    </HydraAdmin>
    <div class="footer">
      <span>
        made by{" "}
        <a href="https://a100.com.pl/" target="_blank">
          A100
        </a>
      </span>
    </div>
  </>
);
