import * as React from "react";
import {
  List,
  TextField,
  FunctionField,
  ImageField,
  Filter,
  TextInput,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Repozytorium zdjęć`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="zdjęć" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="filePath"
            variant="outlined"
            alwaysOn
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <FunctionField
          source="id"
          label="ID"
          render={(record) => `${record.id.split("/")[2]}`}
        />
        <ImageField source="contentUrl" label="Zdjęcie" sortable={false} />
        <TextField source="filePath" label="Nazwa" />
        <CustomActionsButtons
          isDeleteAvailable={true}
          isEditAvailable={true}
          editLabel={"Podmień"}
          label="Akcje"
          notUndoable={true}
        />
      </CustomDataGrid>
    </List>
  );
};
