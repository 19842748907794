import React, { useState } from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  BooleanInput,
  NumberInput,
  TextInput,
  FormDataConsumer,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const transform = (data) => ({
  ...data,
  type: 0,
});

export default (props) => {
  const [type, setType] = useState();
  return (
    <Create {...props} title={"Dodaj baner"} transform={transform}>
      <TabbedForm toolbar={<PostBottomToolbarAdd />}>
        <FormTab label="Zawartość">
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              // const { data, loading, error } = useGetOne(
              //   "banners",
              //   formData.position
              // );
              setType(formData.type);
            }}
          </FormDataConsumer>
          <div className="d-flex w-100 justify-content-around">
            <div class="col-xs-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Nagłówek"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field name="short">
                        {(props) => (
                          <TinyMCEInput
                            source="short"
                            height={200}
                            menubar={false}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        source="url"
                        label="URL"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        source="position"
                        label="Pozycja"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AddImagePopup
                        source="image"
                        reference="media_objects"
                        label="Grafika baneru"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                        initalValue={true}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
