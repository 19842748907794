import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  BooleanInput,
  SelectInput,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={`Dodaj kategorię`}>
      <TabbedForm toolbar={<PostBottomToolbarAdd />}>
        <FormTab label="Zawartość">
          <div className="d-flex w-100 justify-content-around">
            <div class="col-xs-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Nazwa kategorii"
                        variant="outlined"
                        fullWidth={true}
                        validate={requiredField}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="type"
                        variant="outlined"
                        label="Typ"
                        choices={[
                          { id: 0, name: "Normalna kategoria" },
                          { id: 1, name: "Kategoria produktowa" },
                        ]}
                        fullWidth={true}
                        validate={requiredField}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        Treść krótka
                      </label>
                      <Field name="short">
                        {(props) => (
                          <TinyMCEInput
                            source="short"
                            height={200}
                            menubar={false}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AddImagePopup
                        source="image"
                        reference="media_objects"
                        label="Grafika kategorii"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceInput
                        label="Kategoria nadrzędna"
                        source="parent"
                        reference="categories"
                        validate={requiredField}
                        variant="outlined"
                        perPage={5}
                      >
                        <AutocompleteInput
                          optionText={(choice) =>
                            `${choice.name} (${
                              choice.parentName ? choice.parentName : "-"
                            })`
                          }
                        />
                      </ReferenceInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="showInFooter"
                        label="Pokazać w footer"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
