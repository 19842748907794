import * as React from "react";
import {
  List,
  TextField,
  BooleanField,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import EmptyList from "../EmptyList";

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      title={`Typy produktów`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="typów produktów" add={true} />}
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <BooleanField source="status" label="Status" className={classes.root} />
        <CustomActionsButtons label="Akcje" notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
