import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  ImageField,
  ImageInput,
  required,
} from "react-admin";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={"Dodaj zdjęcie"}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <ImageInput
                      source="file"
                      label="Zdjęcie"
                      accept="image/*"
                      fillWidth
                      validate={requiredField}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
