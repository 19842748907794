import React, { useState } from "react";
import {
  TabbedForm,
  FormTab,
  Edit,
  BooleanInput,
  TextInput,
} from "react-admin";
import { Field } from "react-final-form";

import authProvider from "../../config/authProvider";

import {
  PostBottomToolbarEdit,
} from "../CustomElements/PostBottomToolbars";

const CustomTitle = (props) => `Rodzaj produktu ${props.record.name}`;

export default (props) => {
  const [type, setType] = useState();

  return (
    <Edit {...props} title={<CustomTitle />}>
      <TabbedForm toolbar={<PostBottomToolbarEdit />}>
        <FormTab label="Zawartość">
          <div className="d-flex w-100 justify-content-around">
            <div class="col-md-4">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Nazwa"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="status"
                        label="Status"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
