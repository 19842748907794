import React, { useState } from "react";
import { useForm } from "react-final-form";
import { Editor } from "@tinymce/tinymce-react";
import * as _ from "lodash";

const TinyMCEInput = ({ source, ...props }) => {
  const form = useForm();
  const firstInit = _.get(form.getState().initialValues, source, "");
  const [init] = useState(firstInit);

  const debouncedOnChange = _.debounce((text) => {
    form.change(source, text);
  }, 500);
  return (
    <Editor
      apiKey="6opw8w5zbugd1523uz5wbgf0mmletiawv5b2i6p5n4tw6szf"
      init={{
        height: props.height ? props.height : 500,
        relative_urls: false,
        menubar: props.menubar != undefined ? props.menubar : true,
        external_plugins: {
          "image-plugin": "/image-plugin/plugin.js",
          "file-plugin": "/file-plugin/plugin.js",
        },
        plugins: props.plugins
          ? props.plugins
          : [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
        toolbar: props.toolbar
          ? props.toolbar
          : "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help | image-plugin | file-plugin",
      }}
      initialValue={init}
      onEditorChange={(content) => {
        debouncedOnChange(content);
      }}
    />
  );
};

export default TinyMCEInput;
