import React, { useState } from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  BooleanInput,
  SelectInput,
  NumberInput,
  TextInput,
  FormDataConsumer,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

export default (props) => {
  const [type, setType] = useState();

  let customProps = {
    basePath: "/boxes",
    hasCreate: true,
    hasEdit: true,
    hasList: true,
    hasShow: false,
    history: {},
    location: {
      pathname: "/boxes/create",
      search: "",
      hash: "",
      state: undefined,
    },
    match: {
      path: "/boxes/create",
      url: "/boxes/create",
      isExact: true,
      params: {},
    },
    options: {},
    permissions: "ROLE_SUPER_ADMIN,ROLE_USER",
    resource: "banners",
    syncWithLocation: true,
  };

  return (
    <Create {...customProps} title={"Dodaj boks"}>
      <TabbedForm toolbar={<PostBottomToolbarAdd />}>
        <FormTab label="Zawartość">
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              // const { data, loading, error } = useGetOne(
              //   "banners",
              //   formData.position
              // );
              setType(formData.type);
            }}
          </FormDataConsumer>
          <div className="d-flex w-100 justify-content-around">
            <div class="col-xs-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Nagłówek"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field name="short">
                        {(props) => (
                          <TinyMCEInput
                            source="short"
                            height={200}
                            menubar={false}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        source="url"
                        label="URL"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SelectInput
                        source="type"
                        label="Typ"
                        variant="outlined"
                        choices={[
                          // { id: 0, name: "Baner" },
                          { id: 1, name: "Boks" },
                          { id: 2, name: "Boks AR" },
                        ]}
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        source="position"
                        label="Pozycja"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  {type == 1 && (
                    <tr>
                      <td>
                        <SelectInput
                          source="textPosition"
                          label="Pozycjonowanie tekstu"
                          variant="outlined"
                          choices={[
                            { id: 0, name: "Tekst banera po prawej stronie" },
                            { id: 1, name: "Tekst banera po lewej stronie" },
                          ]}
                          fullWidth
                        />
                      </td>
                    </tr>
                  )}
                  {type == 2 && (
                    <tr>
                      <td>
                        <TextInput
                          source="videoUrl"
                          label="Video URL"
                          variant="outlined"
                          fullWidth
                        />
                      </td>
                    </tr>
                  )}
                  {type != 2 && (
                    <tr>
                      <td>
                        {/* <label
                          style={{
                            fontFamily: "arial",
                            color: "rgba(0, 0, 0, 0.54)",
                            display: "block",
                          }}
                        >
                          Grafika baneru
                        </label>
                        <ImageInput
                          source="image"
                          variant="outlined"
                          label=""
                          accept="image/*"
                        >
                          <ImageField source="src" title="title" />
                        </ImageInput> */}
                        <AddImagePopup
                          source="image"
                          reference="media_objects"
                          label="Grafika baneru"
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                        initalValue={true}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
