import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  required,
} from "react-admin";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

const CustomTitle = (props) => `Użytkownik ${props.record.username}`;

const transform = (data) => ({
  ...data,
  roles: Array.isArray(data.roles) ? data.roles.join(",") : data.roles,
});

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />} transform={transform}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="email"
                      label="Adres e-mail"
                      variant="outlined"
                      fullWidth={true}
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <SelectInput
                      source="roles"
                      label="Uprawienia"
                      validate={requiredField}
                      variant="outlined"
                      fullWidth={true}
                      choices={
                        authProvider.hasRole(["ROLE_SUPER_ADMIN"])
                          ? [
                              {
                                id: "ROLE_SUPER_ADMIN,ROLE_USER",
                                name: "Admin wszystkiego",
                              },
                              {
                                id: "ROLE_ADMIN,ROLE_USER",
                                name: "Admin",
                              },
                              { id: "ROLE_USER", name: "Użytkownik" },
                            ]
                          : authProvider.hasRole(["ROLE_ADMIN"])
                          ? [
                              {
                                id: "ROLE_ADMIN,ROLE_USER",
                                name: "Admin",
                              },
                              { id: "ROLE_USER", name: "Użytkownik" },
                            ]
                          : []
                      }
                    />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
