import React, { useState } from "react";
import {
  SelectInput,
  TextInput,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";
import AddImagePopup from "../CustomElements/AddImagePopup";

export default (props) => {
  //console.log(props.type);
  switch (props.type.slug) {
    case "rzepak-1":
      return (
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="t1VarietyName"
                      label="Nazwa odmiany"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t1Farmer"
                      label="Hodowca"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t1VarietyType"
                      label="Typ odmiany"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t1RegistryCountry"
                      label="Rejestracja: kraj i rok"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Krótka charakterystyka produktu, widoczna w nagłówku
                      strony produktowej, pod nazwą produktu
                    </label>
                    <Field name="t1ShortDescription">
                      {(props) => (
                        <TinyMCEInput
                          source="t1ShortDescription"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Wartość użytkowa (skorzystaj z szablonu WYSIWYG o nazwie
                      'Wartość użytkowa dla rzepaku')
                    </label>
                    <Field name="t1UsableValue">
                      {(props) => <TinyMCEInput source="t1UsableValue" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Profil agronomiczny (skorzystaj z szablonów WYSIWYG
                      'Profil Agronomiczny')
                    </label>
                    <Field name="t1AgronomyProfile">
                      {(props) => <TinyMCEInput source="t1AgronomyProfile" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t1CoborLink"
                      label="Link do COBOR"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    case "kukurydza":
      return (
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="t2VarietyName"
                      label="Nazwa odmiany"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t2Fao"
                      label="FAO"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t2Farmer"
                      label="Hodowca"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t1VarietyType"
                      label="Typ odmiany"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t2RegistryCountry"
                      label="Rejestracja: kraj i rok"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t2VarietyType"
                      label="Typ odmiany"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Krótka charakterystyka produktu, widoczna w nagłówku
                      strony produktowej
                    </label>
                    <Field name="t2ShortDescription">
                      {(props) => (
                        <TinyMCEInput
                          source="t2ShortDescription"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    {/* <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Obrazek kolby
                    </label>
                    <ImageInput source="t2FlaskImage" label="" accept="image/*">
                      <ImageField source="src" title="title" fullWidth={true} />
                    </ImageInput> */}
                    <AddImagePopup
                      source="t2FlaskImage"
                      reference="media_objects"
                      label="Obrazek kolby"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {/* <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Przekrój kolby
                    </label>
                    <ImageInput
                      source="t2FlaskSection"
                      label=""
                      accept="image/*"
                    >
                      <ImageField source="src" title="title" fullWidth={true} />
                    </ImageInput> */}
                    <AddImagePopup
                      source="t2FlaskSection"
                      reference="media_objects"
                      label="Przekrój kolby"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {/* <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Przekrój ziarna
                    </label>
                    <ImageInput
                      source="t2GrainSection"
                      label=""
                      accept="image/*"
                    >
                      <ImageField source="src" title="title" fullWidth={true} />
                    </ImageInput> */}
                    <AddImagePopup
                      source="t2GrainSection"
                      reference="media_objects"
                      label="Przekrój ziarna"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Zalecany kierunek użytkowania (skorzystaj z szablonu
                      WYSIWYG o nazwie 'Kukurydza - zalecany kierunek
                      użytkowania')
                    </label>
                    <Field name="t2SuggestedUse">
                      {(props) => <TinyMCEInput source="t2SuggestedUse" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Zalecana obsada roślin przy zbiorze w szt./ha
                    </label>
                    <Field name="t2Recommendations">
                      {(props) => <TinyMCEInput source="t2Recommendations" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Charakterystyka odmiany
                    </label>
                    <Field name="t2Description">
                      {(props) => (
                        <TinyMCEInput
                          source="t2Description"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t2CoborLink"
                      label="Link do COBOR"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Rekomendacja hodowcy (skorzystaj z szablonu WYSIWYG o
                      nazwie 'Rekomendacja hodowcy')
                    </label>
                    <Field name="t2FarmerRecommendation">
                      {(props) => (
                        <TinyMCEInput source="t2FarmerRecommendation" />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Wykresy (skorzystaj z szablonu WYSIWYG o nazwie 'Jeden
                      wykres' lub 'Dwa wykresy')
                    </label>
                    <Field name="t2Chart">
                      {(props) => <TinyMCEInput source="t2Chart" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Wymagania glebowe
                    </label>
                    <Field name="t2SoilRequirements">
                      {(props) => (
                        <TinyMCEInput
                          source="t2SoilRequirements"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    case "zboza-1":
      return (
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="t3Type"
                      label="Typ zboża"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t3VarietyName"
                      label="Nazwa odmiany"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Krótka charakterystyka produktu, widoczna w nagłówku
                      strony produktowej
                    </label>
                    <Field name="t3ShortDescription">
                      {(props) => (
                        <TinyMCEInput
                          source="t3ShortDescription"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t3RegistryCountry"
                      label="Rejestracja: kraj i rok"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Lewa kolumna
                    </label>
                    <Field name="t3Description">
                      {(props) => <TinyMCEInput source="t3Description" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Prawa kolumna
                    </label>
                    <Field name="t3Description2">
                      {(props) => <TinyMCEInput source="t3Description2" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Podsumowanie
                    </label>
                    <Field name="t3Description3">
                      {(props) => <TinyMCEInput source="t3Description3" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t3CoborLink"
                      label="Link do COBOR"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Wykresy (skorzystaj z szablonu WYSIWYG o nazwie 'Jeden
                      wykres' lub 'Dwa wykresy')
                    </label>
                    <Field name="t3Chart">
                      {(props) => <TinyMCEInput source="t3Chart" />}
                    </Field>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    case "chemia":
      return (
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="t4ProductName"
                      label="Nazwa productu"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <SelectInput
                      source="t4ProductType"
                      label="Typ produktu"
                      variant="outlined"
                      choices={[
                        { id: "", name: "wybierz typ" },
                        { id: "herbicyd", name: "herbicyd" },
                        { id: "insektycyd", name: "insektycyd" },
                        { id: "fungicyd", name: "fungicyd" },
                        {
                          id: "regulator wzrostu",
                          name: "regulator wzrostu",
                        },
                        {
                          id: "sklejacz łuszczyn",
                          name: "sklejacz łuszczyn",
                        },
                        {
                          id: "adiuwant",
                          name: "adiuwant",
                        },
                      ]}
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t4Formulation"
                      label="Formulacja"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t4PermitNumber"
                      label="Numer zezwolenia"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                {/* <tr> //TODO fix filterUse?
                    <td>
                      <ReferenceInput
                        reference=""
                        source=""
                        label="Typ"
                        perPage={1000}
                      >
                        <AutocompleteInput variant="outlined" />
                      </ReferenceInput>
                    </td>
                  </tr> */}
                <tr>
                  <td>
                    <TextInput
                      source="t4ActiveIngredient"
                      label="Składniki aktywne"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Dostępne opakowania (skorzystaj z szablonu WYSIWYG o
                      nazwie 'Rozmiary opakowań')
                    </label>
                    <Field name="t4AvailablePackage">
                      {(props) => (
                        <TinyMCEInput
                          source="t4AvailablePackage"
                          height={200}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Technologie
                      <br />
                      Skorzystaj z formatu listy oznaczonego jako
                      'ul.chem-style1'
                    </label>
                    <Field name="t4Technology">
                      {(props) => (
                        <TinyMCEInput source="t4Technology" menubar={false} />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t4Link"
                      label="Odnośnik do strony one-page produktu"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Film reklamowy - link do Youtube w postaci:
                      https://www.youtube.com/embed/xxxxxxxxx
                      <br />
                      - gdzie xxxxxxxxx zastępujemy kodem filmu stanowiącym
                      fragment adresu filmu na Youtube,
                      <br />
                      np. dla https://www.youtube.com/watch?v=nBUsUyDF0J4
                      <br />
                      prawidłowym linkiem jest
                      https://www.youtube.com/embed/nBUsUyDF0J4
                      <br />
                    </label>
                    <TextInput
                      source="t4Advertisment"
                      label=""
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t4LegalInfo"
                      label="Informacje prawne"
                      variant="outlined"
                      fullWidth={true}
                      multiline
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Zwalczane agrofagi
                    </label>
                    <Field name="t4CombatedPests">
                      {(props) => <TinyMCEInput source="t4CombatedPests" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Cechy produktu
                    </label>
                    <Field name="t4ProductFeatures">
                      {(props) => <TinyMCEInput source="t4ProductFeatures" />}
                    </Field>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    case "nawozy":
      return (
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="t5TradeName"
                      label="Nazwa handlowa"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t5ChemicalName"
                      label="Nazwa chemiczna"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t5Formula"
                      label="Formuła"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t5PkwiuNumber"
                      label="Numer PKWiU"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t5CnNumber"
                      label="Numer CN"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Skład
                    </label>
                    <Field name="t5Ingredients">
                      {(props) => (
                        <TinyMCEInput source="t5Ingredients" height={200} />
                      )}
                    </Field>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    case "maszyny":
      return (
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="t6Name"
                      label="Nazwa maszyny"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t6Type"
                      label="Typ maszyny"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Krótki opis maszyny w postaci listy widocznej w nagłówku
                      strony, pod nazwą maszyny
                    </label>
                    <Field name="t6ShortDescription">
                      {(props) => (
                        <TinyMCEInput
                          source="t6ShortDescription"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Pełen opis produktu
                    </label>
                    <Field name="t6Description">
                      {(props) => (
                        <TinyMCEInput source="t6Description" height={200} />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="t6Condition"
                      label="Stan"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};
